import type { RouteRecordRaw } from "vue-router";
import { ERoutesName } from "./types";

import { MainLayout, AppLayout, EmbeddedLayout, DiagramLayout } from "@/widgets/layouts";

import { requiredAuth, notRequiredAuth } from "./guards";

export const routes: RouteRecordRaw[] = [
    // Обязательно без авторизации (страницы входа и регистрации)
    // Не принципиальна авторизация (маркетинговые страницы)
    {
        path: "/",
        component: MainLayout,
        /* beforeEnter: notRequiredAuth, */
        children: [
            {
                path: "blog",
                children: [
                    {
                        path: "",
                        name: ERoutesName.BLOG,
                        component: () => import("@/pages/marketing/blog"),
                    },
                    {
                        path: ":slug",
                        children: [
                            {
                                path: "",
                                name: ERoutesName.BLOG_SUBTITLE,
                                component: () => import("@/pages/marketing/blog"),
                            },
                            {
                                path: ":id",
                                name: ERoutesName.ARTICLE,
                                component: () => import("@/pages/marketing/article"),
                            },
                        ],
                    },
                ],
            },
            {
                path: "",
                name: ERoutesName.MARKETING,
                component: () => import("@/pages/marketing/marketing"),
                alias: ["/:link", "/:link/:sublink"],
            },
            {
                path: "/bpmn/elements/:slug",
                name: ERoutesName.BPMN_ELEMENT,
                component: () => import("@/pages/marketing/element"),
            },
            {
                path: "/contact-sales",
                name: ERoutesName.CONTACT_SALES,
                component: () => import("@/pages/marketing/contact-sales"),
            },
            {
                path: "/not-found",
                name: ERoutesName.NOT_FOUND,
                component: () => import("@/pages/not-found"),
            },
            {
                path: "/app/signup",
                name: ERoutesName.SIGN_UP,
                component: () => import("@/pages/auth/signup"),
            },
            {
                path: "check-email/:email",
                name: ERoutesName.CHECK_EMAIL,
                component: () => import("@/pages/auth/check-email"),
            },
            {
                path: "request-password-restore",
                name: ERoutesName.REQUEST_PASSWORD_RESTORE,
                component: () => import("@/pages/auth/request-password-restore"),
            },
            {
                path: "password-restore/:email",
                name: ERoutesName.PASSWORD_RESTORE,
                component: () => import("@/pages/auth/password-restore"),
            },
            {
                path: "/app/signin",
                name: ERoutesName.LOGIN,
                component: () => import("@/pages/auth/login"),
            },
            {
                path: "login-yandex",
                name: ERoutesName.LOGIN_YANDEX,
                component: () => import("@/pages/auth/login-yandex"),
            },
        ],
    },
    // Страницы APP
    {
        path: "/app",
        children: [
            // Обязательная авторизация (страницы личного кабинета)
            {
                path: "",
                component: AppLayout,
                beforeEnter: requiredAuth,
                children: [
                    {
                        path: "my-processes",
                        name: ERoutesName.APP_MY_PROCESSES,
                        component: () => import("@/pages/app/my-processes"),
                        meta: {
                            title: "Процессы",
                            sidebar: true,
                        },
                    },
                    {
                        path: "diagram/:diagramId/version/:versionId",
                        name: ERoutesName.APP_DIAGRAM_ITEM_VERSION,
                        component: () => import("@/pages/app/diagram-version"),
                        meta: {
                            title: "Версия диаграммы",
                            sidebar: true,
                        },
                    },
                    {
                        path: "plantdiff/:diagramId/:versionId/:lastVersionId",
                        name: ERoutesName.APP_DIFF_PLANT,
                        component: () => import("@/pages/app/plant-diff"),
                        meta: {
                            title: "Сравнение Plant",
                            sidebar: true,
                        },
                    },
                    {
                        path: "diagramdiff",
                        name: ERoutesName.APP_DIFF_DIAGRAM,
                        component: () => import("@/pages/app/diagram-diff"),
                        meta: {
                            title: "Сравнение Plant",
                            sidebar: true,
                        },
                    },
                    {
                        path: "search",
                        name: ERoutesName.APP_SEARCH,
                        component: () => import("@/pages/app/search"),
                        meta: {
                            title: "Поиск",
                            sidebar: true,
                        },
                    },
                    {
                        path: "approvals",
                        children: [
                            {
                                path: "incoming",
                                name: ERoutesName.APP_APPROVALS_INCOMING,
                                component: () => import("@/pages/app/approvals/incoming"),
                                meta: {
                                    title: "Входящие согласования",
                                    sidebar: true,
                                },
                            },
                            {
                                path: "outgoing",
                                name: ERoutesName.APP_APPROVALS_OUTGOING,
                                component: () => import("@/pages/app/approvals/outgoing"),
                                meta: {
                                    title: "Исходящие согласования",
                                    sidebar: true,
                                },
                            },
                            {
                                path: "archive",
                                name: ERoutesName.APP_APPROVALS_ARCHIVE,
                                component: () => import("@/pages/app/approvals/archive"),
                                meta: {
                                    title: "Архив согласований",
                                    sidebar: true,
                                },
                            },
                        ],
                    },
                    {
                        path: "team-process",
                        name: ERoutesName.APP_TEAM_PROCESS,
                        component: () => import("@/pages/app/team-process"),
                        meta: {
                            title: "Процессы команды",
                            sidebar: true,
                        },
                    },
                    {
                        path: "share-with-me",
                        name: ERoutesName.APP_SHARE_WITH_ME,
                        component: () => import("@/pages/app/share-with-me"),
                        meta: {
                            title: "Поделились со мной",
                            sidebar: true,
                        },
                    },
                    {
                        path: "folder",
                        name: ERoutesName.APP_FOLDER,
                        component: () => import("@/pages/app/folder"),
                        meta: {
                            title: "Папки",
                            sidebar: true,
                        },
                    },
                    {
                        path: "process-map",
                        name: ERoutesName.APP_PROCESS_MAP,
                        component: () => import("@/pages/app/process-map"),
                        meta: {
                            title: "Карта процессов",
                            sidebar: true,
                        },
                    },
                    {
                        path: "vertex",
                        name: ERoutesName.APP_VERTEX,
                        component: () => import("@/pages/app/vertex"),
                        meta: {
                            title: "Vertex",
                            sidebar: true,
                        },
                    },
                    {
                        path: "basket",
                        name: ERoutesName.APP_BASKET,
                        component: () => import("@/pages/app/basket"),
                        meta: {
                            title: "Корзина",
                            sidebar: true,
                        },
                    },
                    {
                        path: "generateInvoice",
                        name: ERoutesName.APP_GENERATE_INVOICE,
                        component: () => import("@/pages/app/generate-invoice"),
                        meta: {
                            title: "Генерация счета",
                            sidebar: false,
                        },
                    },
                    {
                        path: "admin",
                        name: ERoutesName.APP_ADMIN,
                        component: () => import("@/pages/app/admin"),
                        meta: {
                            title: "Администрирование",
                            sidebar: false,
                        },
                    },
                    {
                        path: "team",
                        children: [
                            {
                                path: "",
                                name: ERoutesName.APP_TEAM,
                                component: () => import("@/pages/app/team/team"),
                                meta: {
                                    title: "Команда",
                                    sidebar: false,
                                },
                                children: [
                                    {
                                        path: "participants",
                                        name: ERoutesName.APP_TEAM_PARTICIPANTS,
                                        component: () => import("@/pages/app/team/participants"),
                                    },
                                    {
                                        path: "setting",
                                        name: ERoutesName.APP_TEAM_SETTING,
                                        component: () => import("@/pages/app/team/setting"),
                                    },
                                    {
                                        path: "regulation",
                                        name: ERoutesName.APP_TEAM_REGULATION,
                                        component: () => import("@/pages/app/team/regulation"),
                                    },
                                ],
                            },
                            {
                                path: "assignees",
                                children: [
                                    {
                                        path: "",
                                        name: ERoutesName.APP_TEAM_ASSIGNEES,
                                        component: () => import("@/pages/app/team/assignees"),
                                        meta: {
                                            title: "Роли команды",
                                            sidebar: true,
                                        },
                                    },
                                    {
                                        path: ":id",
                                        name: ERoutesName.APP_TEAM_ASSIGNEE,
                                        component: () => import("@/pages/app/team/assignee"),
                                        meta: {
                                            title: "Карточка роли",
                                            sidebar: true,
                                        },
                                    },
                                ],
                            },
                            {
                                path: "assets",
                                children: [
                                    {
                                        path: "",
                                        name: ERoutesName.APP_TEAM_ASSETS,
                                        component: () => import("@/pages/app/team/assets"),
                                        meta: {
                                            title: "Элементы архитектуры",
                                            sidebar: true,
                                        },
                                    },
                                    {
                                        path: ":id",
                                        name: ERoutesName.APP_TEAM_ASSET,
                                        component: () => import("@/pages/app/team/asset"),
                                        meta: {
                                            title: "Карточка элемента архитектуры",
                                            sidebar: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "guests",
                        meta: {
                            title: "Гости",
                            sidebar: true,
                        },
                        children: [
                            {
                                path: "my",
                                name: ERoutesName.APP_GUESTS_MY,
                                component: () => import("@/pages/app/guests/my"),
                            },
                            {
                                path: "team",
                                name: ERoutesName.APP_GUESTS_TEAM,
                                component: () => import("@/pages/app/guests/team"),
                            },
                        ],
                    },
                    {
                        path: "profile",
                        name: ERoutesName.APP_PROFILE,
                        component: () => import("@/pages/app/profile"),
                        meta: {
                            title: "Профиль",
                            sidebar: false,
                        },
                    },
                ],
            },
            // Авторизация не принципиальн (страницы для встраивания)
            {
                path: "",
                component: EmbeddedLayout,
                children: [
                    {
                        path: "diagram/:diagramId/embedded/card",
                        name: ERoutesName.APP_DIAGRAM_EMBEDDED_CARD,
                        component: () => import("@/pages/app/diagram-embedded"),
                    },
                    {
                        path: "folderEmbedded",
                        name: ERoutesName.APP_FOLDER_EMBEDDED,
                        component: () => import("@/pages/app/folder-embedded"),
                    },
                ],
            },
            // Авторизация не принципиальна (страницы подтверждения чего-либо)
            {
                path: "",
                component: MainLayout,
                children: [
                    {
                        path: "activation",
                        name: ERoutesName.ACTIVATION,
                        component: () => import("@/pages/auth/activation"),
                        meta: {
                            title: "Активация аккаунта",
                        },
                    },
                ],
            },
            // Авторизация не принципиальна (страницы диаграммы)
            {
                path: "",
                component: DiagramLayout,
                children: [
                    {
                        path: "diagram",
                        name: ERoutesName.APP_DIAGRAM,
                        component: () => import("@/pages/app/diagram-view"),
                        meta: {
                            title: "Новый процесс",
                        },
                    },
                    {
                        path: "diagram/:id",
                        name: ERoutesName.APP_DIAGRAM_ITEM,
                        component: () => import("@/pages/app/diagram-view"),
                        meta: {
                            title: "Загрузка",
                        },
                    },
                ],
            },
        ],
    },
];
