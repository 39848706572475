export interface ILintRule {
    id?: string;
    ruleId: number;
    type: ELintRuleType;
    header: string;
    description: string;
    additionalInfo: string;
    enable: boolean;
}
export enum ELintRuleType {
    ERROR = "error",
    INFO = "info",
    WARNING = "warning",
}

export enum ETarif {
    TEAM = "TEAM",
    TEAM_TRIAL = "TEAM_TRIAL",
}
