import { computed, ref } from "vue";
import { defineStore } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";

import type { IComment, ICommentCount, ICreateComment } from "..";
import { CommentApi } from "../..";

export const useCommentStore = defineStore("comment", () => {
    const { isLoading: isLoadingCreate, startLoading: startLoadingCreate, finishLoading: finishLoadingCreate } = useLoading();
    const { isLoading: isLoadingGet, startLoading: startLoadingGet, finishLoading: finishLoadingGet } = useLoading();
    const { showError } = useNotification();

    const commentsElementIds = ref<ICommentCount[]>([]);

    const comments = ref<IComment[]>([]);

    const commentsScrollRef = ref<HTMLElement>();
    const commentsScrollRightSidebarRef = ref<HTMLElement>();

    function updateCommentElementIds(elementId: string, number: number): void {
        const index: number = commentsElementIds.value.findIndex(item => item.elementId === elementId);
        if (index >= 0) {
            commentsElementIds.value[index].count += number;
            if (commentsElementIds.value[index].count == 0) commentsElementIds.value.splice(index, 1);
        } else {
            if (number > 0) {
                commentsElementIds.value.push({
                    elementId: elementId,
                    count: 1,
                    unreadCount: 0,
                });
            }
        }
    }

    async function createComment(commentPayload: ICreateComment, files: File[]): Promise<void> {
        try {
            startLoadingCreate();

            const comment = await CommentApi.create(commentPayload);

            if (comment.elementId) {
                updateCommentElementIds(comment.elementId, 1);
            }

            const promises = [];

            for (const file of files) {
                const formData = new FormData();

                formData.append("file", file);

                promises.push(CommentApi.uploadFilesByComment(comment.id, formData));
            }

            const result = await Promise.all(promises);

            comment.files = result;

            comments.value.push(comment);
        } catch (e) {
            showError(e);
        } finally {
            finishLoadingCreate();
        }
    }
    async function getCountByDiagram(diagramId: string) {
        try {
            commentsElementIds.value = await CommentApi.getCountByDiagram(diagramId);
        } catch (e) {
            showError(e);
        }
    }

    async function getCommentsBy(diagramId: string, elementIds?: string[]): Promise<void> {
        try {
            if (isLoadingGet.value) return;

            startLoadingGet();

            if (elementIds) {
                const promises = elementIds.map(item => CommentApi.getByElementId(diagramId, item));
                const resultComments: IComment[][] = await Promise.all(promises);

                comments.value = resultComments.flat();
            } else {
                comments.value = await CommentApi.getByDiagramId(diagramId);
            }
        } catch (e) {
            comments.value = [];
            showError(e);
        } finally {
            finishLoadingGet();
        }
    }

    function removeById(commentId: string) {
        if (comments.value.length) {
            const index = comments.value.findIndex(item => item.id === commentId);
            if (index >= 0) {
                const elementId = comments.value[index].elementId;
                if (elementId) {
                    updateCommentElementIds(elementId, -1);
                }
                comments.value.splice(index, 1);
            }
        }
    }
    function replaceComment(comment: IComment) {
        const index = comments.value.findIndex(item => item.id === comment.id);
        if (index >= 0) {
            comments.value.splice(index, 1, comment);
        }
    }

    function getCountComments(comments: IComment[], elementId?: string): { count: number; unreadCount: number } {
        let count = 0;
        let unreadCount = 0;
        for (const comment of comments) {
            const isElement = elementId ? comment.elementId === elementId : true;

            if (isElement) {
                if (!comment.readed) unreadCount++;
                count++;
            }
        }
        return {
            count,
            unreadCount,
        };
    }

    return {
        isLoadingCreate,
        isLoadingGet,
        commentsElementIds,

        commentsScrollRef,
        commentsScrollRightSidebarRef,

        comments,
        getCommentsBy,
        getCountComments,

        createComment,
        getCountByDiagram,
        updateCommentElementIds,

        removeById,
        replaceComment,
    };
});
