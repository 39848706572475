<template>
    <div class="w-1rem h-1rem">
        <Image v-if="avatarExist" imageClass="border-circle" :src="avatarUrl" @error="avatarExist = false" />
        <span v-else class="pi pi-user"></span>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { API_URL } from "@/shared/config";

const avatarExist = ref(true);

type TProps = {
    username: String;
};

const props = defineProps<TProps>();

const avatarUrl = computed(() => `${API_URL}files/username/${props.username}`);
</script>
