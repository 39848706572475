import CommandInterceptor from "diagram-js/lib/command/CommandInterceptor";

class ReplaceElementBehaviour extends CommandInterceptor {
    constructor(injector: any, modeling: any, eventBus: any) {
        super(eventBus);

        injector.invoke(CommandInterceptor, this);

        this.postExecuted("shape.replace", function (event) {
            const oldShape = event.context.oldShape;
            const newShape = event.context.newShape;

            modeling.resizeLane(
                newShape,
                {
                    x: oldShape.x,
                    y: oldShape.y,
                    width: oldShape.width,
                    height: oldShape.height,
                },
                true,
            );
        });
    }
}

ReplaceElementBehaviour.$inject = ["injector", "modeling", "eventBus"];

export default ReplaceElementBehaviour;
