<template>
    <Button
        :icon="PrimeIcons.PALETTE"
        text
        class="navbar-button"
        severity="contrast"
        v-tooltip.bottom="$t('change-color')"
        @click="colorChangeStore.toggleColorPicker"
    />
    <ChangeColorOverlay />
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";
import { useColorChangeStore, ChangeColorOverlay } from "../";

const colorChangeStore = useColorChangeStore();
</script>

<style scoped></style>
