<template>
    <Dropdown
        v-model="asset"
        :options="assetsByFilter"
        :loading="isLoading"
        showClear
        filter
        filterPlaceholder="Поиск"
        option-label="name"
        option-value="id"
        placeholder="Не выбрано"
    >
        <template #value="{ placeholder }">
            <div v-if="!!selectedAsset" class="flex align-items-center gap-2">
                <span class="pi" :class="selectedAsset.icon"></span>
                <div>{{ selectedAsset.name }}</div>
            </div>
            <span v-else>
                {{ placeholder }}
            </span>
        </template>
        <template #option="{ option }">
            <div class="flex align-items-center gap-2">
                <span class="pi" :class="option.icon"></span>
                <div>{{ option.name }}</div>
            </div>
        </template>
    </Dropdown>
</template>

<script setup lang="ts">
import { onMounted, computed } from "vue";
import { storeToRefs } from "pinia";

import Dropdown from "primevue/dropdown";

import { useAssetStore } from "..";

const assetStore = useAssetStore();
const { assetsByFilter, isLoading } = storeToRefs(assetStore);

const asset = defineModel();

const selectedAsset = computed(() => {
    return assetsByFilter.value.find(item => item.id === asset.value);
});

onMounted(() => {
    assetStore.fetchForFilter();
});
</script>

<style scoped></style>
