<template>
    <Dropdown
        v-model="assignee"
        :options="assigneeByFilter"
        :loading="isLoading"
        showClear
        filter
        filterPlaceholder="Поиск"
        option-label="name"
        option-value="id"
        placeholder="Не выбрано"
    />
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { storeToRefs } from "pinia";

import Dropdown from "primevue/dropdown";

import { useAssigneeStore } from "..";

const assigneeStore = useAssigneeStore();
const { assigneeByFilter, isLoading } = storeToRefs(assigneeStore);

const assignee = defineModel();

onMounted(() => {
    assigneeStore.fetchForFilter();
});
</script>

<style scoped></style>
