import { ref } from "vue";
import { defineStore } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";
import { type IAsset, type IAssetFilter, type IAssetResponse, type ICustomAsset, EAssetType, type IAssetEdge } from ".";
import { AssetApi } from "..";

export const useAssetStore = defineStore("asset", () => {
    const { showError } = useNotification();

    const { isLoading, startLoading, finishLoading } = useLoading();
    const { isLoading: isLoadingAssets, startLoading: startLoadingAssets, finishLoading: finishLoadingAssets } = useLoading();
    const {
        isLoading: isLoadingCustomAssets,
        startLoading: startLoadingCustomAssets,
        finishLoading: finishLoadingCustomAssets,
    } = useLoading();
    const { isLoading: isLoadingTasks, startLoading: startLoadingTasks, finishLoading: finishLoadingTasks } = useLoading();
    const { isLoading: isLoadingByDiagram, startLoading: startLoadingByDiagram, finishLoading: finishLoadingByDiagram } = useLoading();

    const selectedButton = ref<EAssetType>(EAssetType.DOCUMENT);
    function setSelectedButton(type: EAssetType): void {
        selectedButton.value = type;
    }

    const searchModel = ref<string>("");
    function clearSearchModel(): void {
        searchModel.value = "";
    }

    const assetsByFilter = ref<IAsset[]>([]);
    async function fetchForFilter(): Promise<void> {
        try {
            startLoading();
            assetsByFilter.value = await AssetApi.fetchForFilter();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    const assets = ref<IAsset[]>([]);
    const assetsRecords = ref<number>(0);
    async function fetchAllAssets(
        filter: IAssetFilter = { page: 0, size: 10, type: selectedButton.value || EAssetType.DOCUMENT, sort: "updatedOn,desc" },
    ): Promise<void> {
        try {
            startLoadingAssets();

            if (!filter.sort) filter.sort = "updatedOn,desc";

            const response: IAssetResponse = await AssetApi.fetchAll(filter);
            assets.value = response.content;
            assetsRecords.value = response.totalElements;
        } catch (e) {
            assets.value = [];
            assetsRecords.value = 0;
            showError(e);
        } finally {
            finishLoadingAssets();
        }
    }
    async function searchByName(name: string): Promise<void> {
        try {
            startLoadingAssets();

            assets.value = await AssetApi.searchByName(name);
        } catch (e) {
            showError(e);
        } finally {
            finishLoadingAssets();
        }
    }
    function replaceAsset(assetData: IAsset): void {
        const index = assets.value.findIndex(item => item.id === assetData.id);
        if (index >= 0) {
            assets.value.splice(index, 1, assetData);
        } else {
            assetsRecords.value += 1;
            assets.value.unshift(assetData);
            if (assetsRecords.value > 10) assets.value.pop();
        }

        if (asset.value) {
            const edges = asset.value.assetEdges;
            assetData.assetEdges = edges;
            asset.value = assetData;
        }
    }

    const customAssets = ref<ICustomAsset[]>([]);
    const customAssetsRecords = ref<number>(0);
    async function fetchCustomAssets(): Promise<void> {
        try {
            startLoadingCustomAssets();

            customAssets.value = await AssetApi.fetchCustom();
        } catch (e) {
            customAssets.value = [];
            showError(e);
        } finally {
            finishLoadingCustomAssets();
        }
    }
    function replaceCustomAsset(customAsset: ICustomAsset): void {
        const index = customAssets.value.findIndex(item => item.id === customAsset.id);
        if (index >= 0) {
            customAssets.value.splice(index, 1, customAsset);
        } else {
            customAssets.value.unshift(customAsset);
        }
    }

    const customAssetsByDiagram = ref<ICustomAsset[]>([]);
    async function fetchCustomByDiagramId(diagramId: string): Promise<void> {
        try {
            customAssetsByDiagram.value = await AssetApi.fetchCustomByDiagramId(diagramId);
        } catch (e) {
            customAssetsByDiagram.value = [];
            showError(e);
        }
    }

    const usedInTasks = ref<IAssetEdge[]>([]);
    async function getTaskThatUseAsset(id: number): Promise<void> {
        try {
            startLoadingTasks();

            usedInTasks.value = await AssetApi.checkUsage(id);
        } catch (e) {
            usedInTasks.value = [];
            showError(e);
        } finally {
            finishLoadingTasks();
        }
    }

    const assetsByDiagram = ref<IAssetEdge[]>([]);
    async function fetchByDiagramId(diagramId: string): Promise<void> {
        try {
            startLoadingByDiagram();

            assetsByDiagram.value = await AssetApi.fetchByDiagramId(diagramId);
        } catch (e) {
            assetsByDiagram.value = [];
            showError(e);
        } finally {
            finishLoadingByDiagram();
        }
    }
    function replaceAssetsByDiagramByElementId(elementId: string, assets: IAssetEdge[]): void {
        assetsByDiagram.value = assetsByDiagram.value.filter(item => item.fromItemId !== elementId);
        assetsByDiagram.value.push(...assets);
    }
    function changeAssetsByDiagramByAsset(asset: IAsset): void {
        for (const assetEdge of assetsByDiagram.value) {
            if (assetEdge.assetId === asset.id) {
                assetEdge.assetColor = asset.color;
                assetEdge.assetName = asset.name;
                assetEdge.assetDescription = asset.description;
            }
        }
    }
    function deleteAssetsByDiagramBy(elementId: string, edgeId: string): void {
        const index = assetsByDiagram.value.findIndex(item => item.id === edgeId && item.fromItemId === elementId);
        if (index >= 0) assetsByDiagram.value.splice(index, 1);
    }

    const asset = ref<IAsset>();
    async function fetchOneById(id: number): Promise<void> {
        try {
            asset.value = await AssetApi.fetchOneById(id);
        } catch (e) {
            asset.value = undefined;
            showError(e);
        }
    }

    return {
        selectedButton,
        setSelectedButton,

        searchModel,
        clearSearchModel,

        isLoading,
        assetsByFilter,
        fetchForFilter,

        isLoadingAssets,
        assets,
        assetsRecords,
        fetchAllAssets,
        searchByName,
        replaceAsset,

        isLoadingCustomAssets,
        customAssets,
        fetchCustomAssets,
        replaceCustomAsset,

        customAssetsByDiagram,
        fetchCustomByDiagramId,

        isLoadingTasks,
        usedInTasks,
        getTaskThatUseAsset,

        isLoadingByDiagram,
        assetsByDiagram,
        fetchByDiagramId,
        changeAssetsByDiagramByAsset,
        replaceAssetsByDiagramByElementId,
        deleteAssetsByDiagramBy,

        asset,
        fetchOneById,
    };
});
