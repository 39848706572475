import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";
import { ESettingsName, TeamApi, ETypeUpdateSettingsTeam } from "@/entities/Team";
import { useSessionStore } from "@/entities/Session";
import type {
    ITeam,
    ITeamSetting,
    ITeamListSearch,
    IDiagramOption,
    IMyTemplate,
    IPayloadUpdateAllowedDomain,
    ISettingsQuality,
    IPayloadSaveNewSettingsQuality,
    IResponseSettingsQuality,
    ISendInvitedColleague,
} from "@/entities/Team";
import type { ILintRule } from "@/shared/lib/types/app";

export const useTeamStore = defineStore("team", () => {
    const sessionStore = useSessionStore();

    const { showError, showSuccess } = useNotification();
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { isInTeam } = storeToRefs(sessionStore);

    const teamByFilter = ref<ITeamListSearch[]>([]);
    const team = ref<ITeam>();
    const teamSettings = ref<ITeamSetting[]>();
    const diagramOption = ref<IDiagramOption[]>();
    const myTemplates = ref<IMyTemplate[]>([]);
    const settingsQuality = ref<ISettingsQuality>({
        folderRequried: false,
        minimumDiagramScore: 0,
        minumumPercentOfSystems: 0,
        minumumPercentOfAssignees: 0,
        minumumPercentOfDocuments: 0,
    });
    const rullesSettingsTeam = ref<ILintRule[]>([]);

    const scoringSettings = computed(() => {
        if (teamSettings.value) {
            const findedSetting = teamSettings.value.find(item => item.setting === ESettingsName.SCORING);
            if (findedSetting) return JSON.parse(findedSetting.value);
        }
        return [];
    });
    const minDiagramScore = computed(() => getSettingValueByName(ESettingsName.MIN_DIAGRAM_SCORE));
    const minPercentOfAssignees = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_ASSIGNEES));
    const minPercentOfSystems = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_SYSTEMS));
    const minPercentOfDocuments = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_DOCUMENTS));

    function getSettingValueByName(name: ESettingsName): number {
        if (teamSettings.value) {
            const finded = teamSettings.value.find(item => item.setting === name);
            if (finded) {
                const value = JSON.parse(finded.value);
                return Number.isInteger(value) ? value : 0;
            }
        }

        return 0;
    }

    function saveSettingsQuality(quality: IResponseSettingsQuality[]) {
        for (let set of quality) {
            if (set.setting) {
                if (set.setting == ESettingsName.MIN_PERCENT_OF_SYSTEMS)
                    settingsQuality.value.minumumPercentOfSystems = Number(set.value) || 0;
                if (set.setting == ESettingsName.FOLDER_REQURIED) {
                    if (set.value == "true") {
                        settingsQuality.value.folderRequried = true;
                    } else {
                        settingsQuality.value.folderRequried = false;
                    }
                }
                if (set.setting == ESettingsName.MIN_DIAGRAM_SCORE) settingsQuality.value.minimumDiagramScore = Number(set.value) || 0;
                if (set.setting == ESettingsName.MIN_PERCENT_OF_ASSIGNEES)
                    settingsQuality.value.minumumPercentOfAssignees = Number(set.value) || 0;
                if (set.setting == ESettingsName.MIN_PERCENT_OF_DOCUMENTS)
                    settingsQuality.value.minumumPercentOfDocuments = Number(set.value) || 0;
                if (set.setting == ESettingsName.SCORING) rullesSettingsTeam.value = JSON.parse(set.value);
            }
        }
    }

    async function fetchForFilter(): Promise<void> {
        try {
            startLoading();
            teamByFilter.value = await TeamApi.fetchForFilter();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchTeam(): Promise<void> {
        try {
            team.value = await TeamApi.fetchMy();
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function fetchTeamSettings(): Promise<void> {
        try {
            if (isInTeam.value) {
                teamSettings.value = await TeamApi.fetchSettings();
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function addUserTeam(payload: ISendInvitedColleague) {
        try {
            if (team.value) {
                await TeamApi.addUserTeam(team.value.id, payload);
                showSuccess("Пользователю отправленно приглашение!");
                await fetchTeam();
                return true;
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getOnlyDiagrams(): Promise<void> {
        try {
            diagramOption.value = await TeamApi.getOnlyDiagrams();
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getMyTemplates(): Promise<void> {
        try {
            myTemplates.value = await TeamApi.getMyTemplates();
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function deleteTemplate(id: string) {
        try {
            await TeamApi.deleteTemplate(id);
            await getMyTemplates();
            showSuccess("Шаблон успешно удален");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function saveTemplate(payload: any) {
        try {
            await TeamApi.saveTemplate(payload);

            await getMyTemplates();
            showSuccess("Шаблон успешно загружен");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function updateSettingsTeam(payload: IPayloadUpdateAllowedDomain, type: string) {
        try {
            team.value = await TeamApi.updateSettingsTeam(payload);
            if (type == ETypeUpdateSettingsTeam.API) {
                showSuccess("API ключ успешно обновлен");
            } else if (type == ETypeUpdateSettingsTeam.NAME) {
                showSuccess("Название успешно обновлено");
            } else if (type == ETypeUpdateSettingsTeam.DOMAIN) {
                showSuccess("Домен успешно обновлен");
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getSettings() {
        try {
            const res: IResponseSettingsQuality[] = await TeamApi.getSettings();
            saveSettingsQuality(res);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function saveNewSettingsQuality(payload: IPayloadSaveNewSettingsQuality[]) {
        try {
            const res: IResponseSettingsQuality[] = await TeamApi.saveNewSettingsQuality(payload);
            saveSettingsQuality(res);
            showSuccess("Настройки успешно сохранены");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    return {
        isLoading,
        team,
        teamByFilter,
        teamSettings,
        scoringSettings,
        minDiagramScore,
        minPercentOfAssignees,
        minPercentOfDocuments,
        minPercentOfSystems,
        diagramOption,
        rullesSettingsTeam,
        myTemplates,
        settingsQuality,
        fetchForFilter,
        fetchTeam,
        fetchTeamSettings,
        addUserTeam,
        getOnlyDiagrams,
        getMyTemplates,
        deleteTemplate,
        saveTemplate,
        updateSettingsTeam,
        getSettings,
        saveNewSettingsQuality,
    };
});
