import { computed, ref } from "vue";
import { defineStore } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";
import {
    EProcessSecure,
    type IProcess,
    type IAutosaveDiagram,
    type IDiagram,
    type IOnePorcessResponse,
    type IUploadDiagram,
    type IOverlaySettings,
} from "..";
import { ProcessApi } from "../..";
import { useRouter } from "vue-router";
import { ERoutesName } from "@/app/providers";
import { setTitlePage } from "@/shared/lib/browser";

export const useDiagramStore = defineStore("diagram", () => {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();
    const router = useRouter();

    const diagramAccessAllowed = ref(true);
    const diagram = ref<IDiagram>();
    const diagramPermissionByMe = ref<EProcessSecure>();
    const isZoom = ref<boolean>(false);

    const diagramCallActivitySearch = ref<IProcess[]>();

    const whenAutoSave = ref<Date>();

    const diagramId = computed<string>(() => diagram.value?.id || "");

    function resetDiagram() {
        diagram.value = undefined;
        diagramPermissionByMe.value = EProcessSecure.EDIT;
        whenAutoSave.value = undefined;
    }
    async function setDiagram(diagramData: IDiagram) {
        diagram.value = diagramData;

        await router.replace({ name: ERoutesName.APP_DIAGRAM_ITEM, params: { id: diagramData.id } });
        setTitlePage(diagramData.name);
    }
    function setDiagramBody(xml: string): void {
        if (diagram.value) {
            diagram.value.body = xml;
        }
    }

    async function fetchCallActivitySearch() {
        try {
            diagramCallActivitySearch.value = [];
            const data = await ProcessApi.fetchCallActivitySearch();
            diagramCallActivitySearch.value = data;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    async function fetchById(id: string): Promise<void> {
        try {
            startLoading();

            diagram.value = undefined;
            diagramAccessAllowed.value = true;
            isZoom.value = true;

            const data: IOnePorcessResponse = await ProcessApi.fetchById(id);
            diagram.value = data.diagram;
            diagramPermissionByMe.value = data.permission;
        } catch (e: any) {
            if (e.status === 403) {
                diagramAccessAllowed.value = false;
            } else {
                showError(e?.message || e);
            }
        } finally {
            finishLoading();
        }
    }
    async function createDiagram(payload: IUploadDiagram) {
        try {
            isZoom.value = false;

            const data = await ProcessApi.uploadDiagram(payload);
            setDiagram(data);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function autosave(payload: IAutosaveDiagram) {
        try {
            if (diagram.value) {
                const data = await ProcessApi.autosave(diagram.value.id, payload);
                diagram.value.autosaveIndex = data.autosaveIndex;
                diagram.value.body = payload.body;

                whenAutoSave.value = new Date();
            }
        } catch (e) {
            showError(e);
        }
    }

    const overlaySettings = ref<IOverlaySettings>({
        assignees: false,
        comments: false,
        description: false,
        documents: false,
        duration: false,
        incomingLinks: false,
        links: false,
        positions: false,
        systems: false,
    });
    async function fetchOverlaySettingsById(diagramId: string): Promise<void> {
        try {
            overlaySettings.value = await ProcessApi.fetchOverlaySettingsById(diagramId);
        } catch (e) {
            showError(e);
        }
    }

    return {
        isLoading,
        isZoom,
        diagram,
        diagramAccessAllowed,
        diagramPermissionByMe,
        whenAutoSave,
        diagramCallActivitySearch,
        diagramId,

        resetDiagram,
        fetchById,
        createDiagram,
        autosave,
        setDiagram,
        fetchCallActivitySearch,
        setDiagramBody,

        overlaySettings,
        fetchOverlaySettingsById,
    };
});
