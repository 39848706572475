<template>
    <div class="flex gap-2 px-2 py-1 border-round surface-50 border-1 border-200 justify-content-between">
        <div class="flex gap-2">
            <div class="flex gap-1">
                <ToggleTokenSimulator />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <Button
                    :icon="iconFullScreen"
                    :text="!menuSettings.isFullscreen"
                    class="navbar-button button_clean-outline"
                    severity="contrast"
                    v-tooltip.bottom="$t('fullscreen')"
                    @click="actionMenuStore.toggleFullscreen"
                />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <Button
                    :icon="PrimeIcons.MAP"
                    :text="!menuSettings.isMinimap"
                    class="navbar-button"
                    severity="contrast"
                    v-tooltip.bottom="$t('open-minimap')"
                    @click="actionMenuStore.toggleMinimap"
                />
                <Button :icon="PrimeIcons.SEARCH_PLUS" text severity="contrast" class="navbar-button" v-tooltip.bottom="$t('zoom-in')" />
                <Button :icon="PrimeIcons.SEARCH_MINUS" text severity="contrast" class="navbar-button" v-tooltip.bottom="$t('zoom-out')" />
                <Button :icon="PrimeIcons.UNDO" text severity="contrast" class="navbar-button text-600" v-tooltip.bottom="$t('undo')" />
                <Button
                    :icon="PrimeIcons.REFRESH"
                    text
                    severity="contrast"
                    class="navbar-button text-600"
                    v-tooltip.bottom="$t('do-last-action')"
                />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <ChangeColor />
                <Button :icon="PrimeIcons.COPY" text severity="contrast" class="navbar-button" v-tooltip.bottom="$t('copy-part')" />
                <Button :icon="PrimeIcons.CLIPBOARD" text severity="contrast" class="navbar-button" v-tooltip.bottom="$t('paste-part')" />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <ToggleCheckErrors />
                <Button
                    :icon="PrimeIcons.LIST_CHECK"
                    text
                    severity="contrast"
                    class="navbar-button"
                    :loading="isLoading"
                    v-tooltip.bottom="$t('auto-numbering')"
                    @click="async () => setAutoNumerate()"
                />
                <Button
                    :icon="PrimeIcons.BAN"
                    text
                    severity="contrast"
                    class="navbar-button"
                    v-tooltip.bottom="$t('clear-numbering')"
                    :loading="isLoading"
                    @click="async () => setAutoNumerate(true)"
                />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <Button icon="pi pi-question-circle" text severity="contrast" class="navbar-button" v-tooltip.bottom="$t('help')" />
                <Button
                    :icon="PrimeIcons.ANGLE_UP"
                    :text="!menuSettings.isHiddenMenu"
                    severity="contrast"
                    class="navbar-button"
                    v-tooltip.bottom="'Скрыть Header'"
                    @click="actionMenuStore.toggleHeaderVisible"
                />
            </div>
        </div>
        <div class="flex gap-1">
            <Button
                :icon="PrimeIcons.BARS"
                text
                class="h-2rem button_clean-outline text-500"
                label="Оверлеи"
                severity="contrast"
                :pt="{ label: 'font-normal' }"
                @click="e => overlayPanel?.toggle(e)"
            />
            <IconField iconPosition="left" class="py-0">
                <InputIcon :class="PrimeIcons.SEARCH" />
                <InputText class="button_clean-outline py-0 h-full" v-model="searchModel" placeholder="Поиск" />
            </IconField>
        </div>
    </div>

    <OverlayPanel ref="overlayPanel">
        <div class="overlay-list">
            <div class="flex flex-column gap-2" v-if="overlaySettings">
                <template v-for="item of overlays" :key="item.name">
                    <Divider v-if="item.divider" class="m-1" />
                    <div v-else-if="item.key" class="flex align-items-center gap-3">
                        <div class="flex align-items-center">
                            <Checkbox v-model="overlaySettings[item.key]" :inputId="item.key" :name="item.key" :binary="true" />
                            <label :for="item.key" class="ml-2 white-space-normal"> {{ getNameSetting(item.key) }} </label>
                        </div>
                        <Shortcut>Alt+{{ item.hotKey }}</Shortcut>
                    </div>
                </template>
            </div>
            <div class="flex flex-column gap-2" v-if="customAssetsByDiagram">
                <div class="flex align-items-center" v-for="item of customAssetsByDiagram" :key="item.id">
                    <Checkbox v-model="item.overlaySettings" :inputId="item.id" :name="item.code" :binary="true" />
                    <label :for="item.id" class="ml-2 white-space-normal"> {{ item.name }} </label>
                </div>
            </div>
        </div>
    </OverlayPanel>
</template>

<script setup lang="ts">
import { watch, onUnmounted, ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";

import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { PrimeIcons } from "primevue/api";
import type OverlayPanel from "primevue/overlaypanel";

import { EOverlaySettingsName, useDiagramActionMenuStore, useDiagramStore } from "@/entities/Process";
import { useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";
import { useAssetStore } from "@/entities/Asset";

import { ChangeColor } from "@/features/Diagram-editor/change-color";
import { ToggleCheckErrors } from "@/features/Diagram-editor/toggle-check-errors";
import { useInstallationOverlays } from "@/features/Overlay/installation";
import { useAutoNumerate } from "@/features/Diagram-editor/auto-numerate";
import { ToggleTokenSimulator } from "@/features/Diagram-editor/toggle-token-simulator";

import { useLinterOverlay } from "@/widgets/app/process";
import { Shortcut } from "@/shared/ui/shortcut";
import { KeyboardManager, type IKeyHandler } from "@/shared/lib/browser";

const actionMenuStore = useDiagramActionMenuStore();
const bpmnOverlayStore = useBpmnModelerOverlayStore();
const diagramStore = useDiagramStore();
const assetStore = useAssetStore();

const { menuSettings } = storeToRefs(actionMenuStore);
const { overlaySettings } = storeToRefs(diagramStore);
const { customAssetsByDiagram } = storeToRefs(assetStore);

const { linterErrors } = useLinterOverlay();
const { installationOverlays } = useInstallationOverlays();
const { isLoading, setAutoNumerate } = useAutoNumerate();

interface IOverlayList {
    name?: string;
    hotKey?: number;
    key?: string;
    divider?: boolean;
}

const keyboardManager = new KeyboardManager();

const overlayPanel = ref<OverlayPanel>();
const searchModel = ref<string>("");

const iconFullScreen = computed<string>(() => {
    if (menuSettings.value.isFullscreen) return PrimeIcons.ARROW_DOWN_LEFT_AND_ARROW_UP_RIGHT_TO_CENTER;
    return PrimeIcons.ARROW_UP_RIGHT_AND_ARROW_DOWN_LEFT_FROM_CENTER;
});

const overlays = computed<IOverlayList[]>(() => {
    return [
        { name: getNameSetting("assignees"), hotKey: 1, key: "assignees" },
        { name: getNameSetting("positions"), hotKey: 2, key: "positions" },
        { name: getNameSetting("systems"), hotKey: 3, key: "systems" },
        { name: getNameSetting("documents"), hotKey: 4, key: "documents" },
        { divider: true },
        { name: getNameSetting("description"), hotKey: 5, key: "description" },
        { name: getNameSetting("comments"), hotKey: 6, key: "comments" },
        { name: getNameSetting("duration"), hotKey: 7, key: "duration" },
        { divider: true },
        { name: getNameSetting("incomingLinks"), hotKey: 8, key: "incomingLinks" },
        { name: getNameSetting("links"), hotKey: 9, key: "links" },
    ];
});

function getNameSetting(name: unknown): string {
    const key = name as keyof typeof EOverlaySettingsName;
    return EOverlaySettingsName[key];
}

watch(
    overlaySettings,
    () => {
        installationOverlays();
    },
    {
        deep: true,
    },
);

watch(
    () => menuSettings.value.isCheckErrors,
    async value => {
        await bpmnOverlayStore.clearOverlays(true);

        if (value) {
            await linterErrors();
        } else {
            installationOverlays();
        }
    },
);

onMounted(() => {
    keyboardManager.mount();

    const handlers: IKeyHandler[] = [];
    for (const item of overlays.value) {
        if (item.key) {
            handlers.push({
                code: `Digit${item.hotKey}`,
                func: () => {
                    overlaySettings.value[item.key as string] = !overlaySettings.value[item.key as string];
                    return true;
                },
                altKey: true,
            });
        }
    }

    handlers.push({
        code: "KeyA",
        func: () => {
            const keys: string[] = Object.keys(overlaySettings.value);
            const status: boolean = overlaySettings.value[keys[0]];

            for (const key of keys) {
                overlaySettings.value[key] = !status;
            }

            for (const item of customAssetsByDiagram.value) {
                item.overlaySettings = !status;
            }
        },
        altKey: true,
        shiftKey: true,
    });

    keyboardManager.addKeyHandlers(handlers);
});

onUnmounted(() => {
    actionMenuStore.resetMenu();
    keyboardManager.unmount();
});
</script>

<style scoped lang="scss">
.overlay-list {
    max-width: 450px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}
</style>
