<template>
    <div class="surface-50 border-1 border-200 border-round flex flex-column gap-3 w-25rem">
        <div class="w-full flex justify-content-center pt-3 pb-0">
            <SelectButton v-model="selectedTypeView" :options="typeViews" :allow-empty="false" :pt="{ button: 'p-2' }" />
        </div>
        <div class="overflow-y-auto scroll-invisible relative h-full">
            <ul class="list-none p-2 pt-0 m-0 flex flex-column gap-1 absolute top-0 left-0 right-0 bottom-0">
                <template v-if="isLoading">
                    <Skeleton class="surface-200" width="100%" height="2.55rem" />
                    <li v-for="item of 8">
                        <Skeleton class="surface-200 ml-4" width="92%" height="2.55rem" />
                    </li>
                    <Skeleton class="surface-200" width="100%" height="2.55rem" />
                </template>
                <template v-else>
                    <li v-for="item of menuItems">
                        <SidebarItem v-bind="item" />
                    </li>
                </template>
            </ul>
        </div>
        <div class="w-full flex justify-content-center pt-0 pb-3">
            <ProcessChangeView />
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { PrimeIcons } from "primevue/api";

import { useLoading } from "@/shared/lib/composables";
import { useLocalStorage } from "@/shared/lib/browser";
import { TYPE_VIEW_KEY } from "@/shared/config";

import { ProcessChangeView } from "@/features/Process/change-view";

import SidebarItem from "./SidebarItem.vue";
import type { IMenuItem } from "../model";

import { ERoutesName } from "@/app/providers";

import { useAssetStore } from "@/entities/Asset";
import { storeToRefs } from "pinia";

const assetStore = useAssetStore();
const { selectedButton } = storeToRefs(assetStore);

enum ETypeView {
    PERSONAL = "Личное",
    TEAM = "Команда",
}

const props = defineProps({
    countApprovals: Number,
});

const router = useRouter();
const { isLoading, startLoading, finishLoading } = useLoading();
const { setLSValue, value } = useLocalStorage(TYPE_VIEW_KEY);

const selectedTypeView = ref(value || ETypeView.PERSONAL);

const typeViews = ref(Object.values(ETypeView));

const personalMenuItems = computed<IMenuItem[]>(() => [
    {
        label: "Процессы",
        icon: PrimeIcons.BRIEFCASE,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
                route: { name: ERoutesName.APP_DIAGRAM },
            },
            {
                label: "Поиск",
                icon: PrimeIcons.SEARCH,
                route: { name: ERoutesName.APP_SEARCH },
            },
            {
                label: "Все",
                icon: PrimeIcons.LIST,
                route: { name: ERoutesName.APP_MY_PROCESSES },
            },
            {
                label: "Поделились со мной",
                icon: PrimeIcons.FILE_IMPORT,
                route: { name: ERoutesName.APP_SHARE_WITH_ME },
            },
            {
                label: "Поделился я",
                icon: PrimeIcons.FILE_EXPORT,
                route: { name: ERoutesName.APP_GUESTS_MY },
            },
            {
                label: "Избранные",
                icon: PrimeIcons.STAR,
                disabled: true,
            },
            {
                label: "Теги",
                icon: PrimeIcons.TAG,
                disabled: true,
            },
            {
                label: "Корзина",
                icon: PrimeIcons.TRASH,
                route: { name: ERoutesName.APP_BASKET },
            },
        ],
    },
    {
        label: "Согласования",
        icon: PrimeIcons.CHECK_CIRCLE,
        badge: props.countApprovals,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
            },
            {
                label: "Входящие",
                icon: PrimeIcons.ARROW_RIGHT,
                route: { name: ERoutesName.APP_APPROVALS_INCOMING },
            },
            {
                label: "Исходящие",
                icon: PrimeIcons.ARROW_LEFT,
                route: { name: ERoutesName.APP_APPROVALS_OUTGOING },
            },
            {
                label: "Архив",
                icon: PrimeIcons.BOOK,
                route: { name: ERoutesName.APP_APPROVALS_ARCHIVE },
            },
        ],
    },
]);
const teamMenuItems = computed<IMenuItem[]>(() => [
    {
        label: "Процессы",
        icon: PrimeIcons.BRIEFCASE,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
                route: { name: ERoutesName.APP_DIAGRAM },
            },
            {
                label: "Поиск",
                icon: PrimeIcons.SEARCH,
                route: { name: ERoutesName.APP_SEARCH },
            },
            {
                label: "Все",
                icon: PrimeIcons.LIST,
                route: { name: ERoutesName.APP_TEAM_PROCESS },
            },
            {
                label: "Папки",
                icon: PrimeIcons.FOLDER,
                route: { name: ERoutesName.APP_FOLDER },
            },
            {
                label: "Гости команды",
                icon: PrimeIcons.FILE_EXPORT,
                route: { name: ERoutesName.APP_GUESTS_TEAM },
            },
            {
                label: "Реестр",
                icon: PrimeIcons.BOX,
                disabled: true,
            },
            {
                label: "Теги",
                icon: PrimeIcons.TAG,
                disabled: true,
            },
            {
                label: "Корзина",
                icon: PrimeIcons.TRASH,
                route: { name: ERoutesName.APP_BASKET },
            },
        ],
    },
    {
        label: "Роли",
        icon: PrimeIcons.USERS,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
                route: { name: ERoutesName.APP_TEAM_ASSIGNEES, query: { create: "true" } },
                cannotByActive: true,
            },
            {
                label: "Все роли",
                icon: PrimeIcons.USERS,
                route: { name: ERoutesName.APP_TEAM_ASSIGNEES },
            },
        ],
    },
    {
        label: "Элементы архитектуры",
        icon: PrimeIcons.OBJECTS_COLUMN,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
                route: { name: ERoutesName.APP_TEAM_ASSETS, query: { tab: "0", create: "true", type: selectedButton.value } },
                cannotByActive: true,
            },
            {
                label: "Создать определение",
                icon: PrimeIcons.FILE_EDIT,
                route: { name: ERoutesName.APP_TEAM_ASSETS, query: { tab: "1", create: "true" } },
                cannotByActive: true,
            },
            {
                label: "Все элементы",
                icon: PrimeIcons.TABLE,
                route: { name: ERoutesName.APP_TEAM_ASSETS },
            },
        ],
    },
    {
        label: "Оргструктура",
        icon: PrimeIcons.BUILDING,
        disabled: true,
        children: [
            {
                label: "Схема",
                icon: PrimeIcons.SITEMAP,
                disabled: true,
            },
            {
                label: "Сотрудники",
                icon: PrimeIcons.USERS,
                disabled: true,
            },
        ],
    },
    {
        label: "Архитектура",
        icon: PrimeIcons.MICROCHIP,
        disabled: true,
    },
    {
        label: "Аналитика",
        icon: PrimeIcons.CHART_BAR,
        disabled: true,
    },
    {
        label: "Camunda",
        icon: PrimeIcons.CODE,
        disabled: true,
        children: [
            {
                label: "Серверы",
                icon: PrimeIcons.SERVER,
                disabled: true,
            },
            {
                label: "Управление",
                icon: PrimeIcons.COG,
                disabled: true,
            },
        ],
    },
]);

const menuItems = computed(() => {
    switch (selectedTypeView.value) {
        case ETypeView.PERSONAL:
            return personalMenuItems.value;
        case ETypeView.TEAM:
            return teamMenuItems.value;
        default:
            return personalMenuItems.value;
    }
});

watch(selectedTypeView, async value => {
    startLoading();

    setLSValue(value);

    switch (value) {
        case ETypeView.PERSONAL:
            await router.replace({ name: ERoutesName.APP_MY_PROCESSES });
            break;
        case ETypeView.TEAM:
            await router.replace({ name: ERoutesName.APP_TEAM_PROCESS });
            break;
        default:
            await router.replace({ name: ERoutesName.APP_MY_PROCESSES });
    }
    finishLoading();
});

onMounted(() => {
    setLSValue(selectedTypeView.value);
});
</script>
