<template>
    <div class="flex flex-column gap-2 align-items-start">
        <div class="flex gap-2 align-items-center text-sm">
            <b class="text-lg ml-2" v-tooltip.bottom="diagram?.name">{{ diagramName }}</b>
            <div v-if="diagramFolder" class="flex gap-1 text-primary" v-tooltip.bottom="diagram?.teamFolderName">
                <span :class="PrimeIcons.FOLDER"></span>
                <span>{{ diagramFolder }}</span>
            </div>
            <div class="flex gap-2">
                <span class="font-semibold" v-if="diagramUser">{{ diagramUser }}</span>
                <div v-if="whenAutoSave" class="flex gap-1 align-items-end text-green-500 ml-1">
                    <span :class="PrimeIcons.CLOUD_UPLOAD"></span>
                    <span>Сохранено</span>
                    <span>{{ timeAgo(whenAutoSave) }}</span>
                </div>
                <span v-else class="white-space-nowrap">{{ diagramUpdated }}</span>
            </div>
        </div>
        <div class="flex gap-3 align-items-center">
            <div class="flex gap-2">
                <Button
                    v-if="isAuth"
                    label="Сохранить"
                    :icon="PrimeIcons.SAVE"
                    class="h-full py-1 px-2 text-600"
                    severity="contrast"
                    text
                    v-tooltip.bottom="buttonSaveText"
                    :pt="{ label: 'font-normal' }"
                    @click="onOpenSaveAsProcess"
                />
                <Button
                    label="Скачать"
                    :icon="PrimeIcons.DOWNLOAD"
                    class="h-full py-1 px-2 text-600"
                    severity="contrast"
                    text
                    :pt="{ label: 'font-normal' }"
                />
                <Button
                    v-if="diagram && isAuth"
                    label="Поделиться"
                    :icon="PrimeIcons.SHARE_ALT"
                    class="h-full py-1 px-2 text-600"
                    severity="contrast"
                    text
                    :pt="{ label: 'font-normal' }"
                    @click="() => onShareProcess(diagram)"
                />
                <Button
                    v-if="isFullAccess && diagram && isAuth && false"
                    label="В шаблон"
                    :icon="PrimeIcons.CLONE"
                    class="h-full py-1 px-2 text-600"
                    severity="contrast"
                    text
                    :pt="{ label: 'font-normal' }"
                ></Button>
                <Button
                    v-if="diagram && isAuth"
                    :icon="PrimeIcons.TRASH"
                    class="h-full py-1 px-2"
                    text
                    severity="danger"
                    v-tooltip.bottom="'Удалить процесс'"
                    :pt="{ label: 'font-normal', root: 'button_clean-outline' }"
                    @click="() => onDeleteProcess(diagram)"
                />
            </div>
            <ProcessStatus v-if="diagram && isAuth" :status="diagram.status" @click="() => changeStatusRef.show(diagram?.id)" />
            <Tag v-if="diagramProcessType" class="select-none" :severity="getSeverityProcessTypeBy(diagramProcessType)">
                <div class="flex gap-2 p-1 align-items-center">
                    <span>{{ diagramProcessType }}</span>
                    <RouterLink
                        v-if="diagramLinkIdIcon"
                        :to="{ name: ERoutesName.APP_DIAGRAM_ITEM, params: { id: diagram?.linkedDiagramId } }"
                        class="flex"
                        v-tooltip.right="diagram?.linkedDiagramName"
                    >
                        <span class="cursor-pointer hover:text-red-500" :class="diagramLinkIdIcon"></span>
                    </RouterLink>
                </div>
            </Tag>
        </div>
    </div>

    <ChangeStatus ref="changeStatusRef" />
    <ShareProcess ref="shareProcessRef" />
    <SaveAsProcess ref="saveAsRef" :save-button-text="buttonSaveText" :diagram="diagram" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import { ERoutesName } from "@/app/providers";

import { DEFAULT_DIAGRAM_NAME } from "@/shared/config";
import { formatDefaultDateTime, timeAgo } from "@/shared/lib/utils/date";

import { useDiagramStore, ProcessStatus, getSeverityProcessTypeBy } from "@/entities/Process";
import { useSessionStore } from "@/entities/Session";
import { EProcessSecure, type IDiagram } from "@/entities/Process/model";

import { ChangeStatus } from "@/features/Process/change-status";
import { ShareProcess } from "@/features/Process/share";
import { useDeleteProcess } from "@/features/Process/remove";
import { SaveAsProcess } from "@/features/Process/save-as";
import { PrimeIcons } from "primevue/api";

const MAX_COUNT_NAME = 25;

const diagramStore = useDiagramStore();
const sessionStore = useSessionStore();
const { deleteProcesses } = useDeleteProcess();
const router = useRouter();

const { diagram, diagramPermissionByMe, whenAutoSave } = storeToRefs(diagramStore);
const { isFullAccess, isAuth } = storeToRefs(sessionStore);

const changeStatusRef = ref();
const shareProcessRef = ref();
const saveAsRef = ref();

const buttonSaveText = computed(() => {
    if (diagram.value) {
        if (diagramPermissionByMe.value === EProcessSecure.EDIT && isAuth.value) {
            return "Сохранить как версию";
        }
        if (diagramPermissionByMe.value !== EProcessSecure.EDIT && isAuth.value) {
            return "Сделать копию в свои процессы";
        }
    }
    return "Сохранить";
});

const diagramName = computed(() => {
    if (diagram.value) {
        const { name } = diagram.value;
        return name.length > MAX_COUNT_NAME ? `${name.substring(0, MAX_COUNT_NAME)}...` : name;
    }
    return DEFAULT_DIAGRAM_NAME;
});
const diagramUpdated = computed(() => {
    if (diagram.value) {
        return formatDefaultDateTime(diagram.value.updatedOn || diagram.value.createdOn);
    }
    return formatDefaultDateTime(new Date());
});
const diagramFolder = computed(() => {
    if (diagram.value) {
        const { teamFolderName } = diagram.value;
        return teamFolderName && teamFolderName.length > MAX_COUNT_NAME
            ? `${teamFolderName.substring(0, MAX_COUNT_NAME)}...`
            : teamFolderName;
    }
    return null;
});
const diagramUser = computed(() => diagram.value?.updateBy || null);
const diagramProcessType = computed(() => diagram.value?.processType || null);
const diagramLinkIdIcon = computed(() => (diagram.value?.linkedDiagramId ? PrimeIcons.LINK : undefined));

async function onDeleteProcess(diagram: undefined | IDiagram) {
    if (diagram) {
        const result = await new Promise((resolve, reject) => {
            deleteProcesses([diagram], { resolve, reject });
        });
        if (result) {
            router.replace({ name: ERoutesName.APP_MY_PROCESSES });
        }
    }
}
function onShareProcess(diagram: undefined | IDiagram) {
    if (diagram) {
        shareProcessRef.value.show(diagram.id);
    }
}
function onOpenSaveAsProcess() {
    saveAsRef.value.show();
}
</script>

<style scoped lang="scss"></style>
