import { httpClient } from "@/shared/api";
import type { AxiosResponse } from "axios";
import type {
    ITeam,
    ITeamListSearch,
    ITeamSetting,
    IGetAdminTeam,
    IContent,
    ISendInvitedColleague,
    IPayloadChangeRights,
    IDiagramOption,
    IMyTemplate,
    IPayloadUpdateAllowedDomain,
    IResponseSettingsQuality,
    IPayloadSaveNewSettingsQuality,
} from "..";

export class TeamApi {
    static async fetchForFilter(): Promise<ITeamListSearch[]> {
        const response: AxiosResponse = await httpClient.get("/diagram-search/team-list");
        return response.data;
    }
    static async fetchMy(): Promise<ITeam> {
        const response: AxiosResponse = await httpClient.get("/team");
        return response.data;
    }
    static async fetchSettings(): Promise<ITeamSetting[]> {
        const response: AxiosResponse = await httpClient.get("/team/settings");
        return response.data;
    }
    static async getAdminTeam(): Promise<IGetAdminTeam> {
        const response: AxiosResponse = await httpClient.get("/admin/teams");
        return response.data;
    }
    static async promoteToAdmin(memberId: string): Promise<IContent> {
        const response: AxiosResponse = await httpClient.post("/admin/promoteToAdmin?memberId=" + memberId);
        return response.data;
    }
    static async addToTeam(userEmail: string, teamId: string | undefined): Promise<IContent> {
        const response: AxiosResponse = await httpClient.post("/admin/addUserToTeam?userEmail=" + userEmail + "&teamId=" + teamId);
        return response.data;
    }
    static async searchByTeamName(filter: string): Promise<IContent[]> {
        const response: AxiosResponse = await httpClient.get("/admin/team/search?name=" + filter);
        return response.data;
    }
    static async makeAuthorProcessesParticipants(payload: IPayloadChangeRights): Promise<void> {
        await httpClient.post("/team/changeOwnership", payload);
    }
    static async changeRightsParticipants(payload: IPayloadChangeRights): Promise<ITeam> {
        const response: AxiosResponse = await httpClient.post("/team/changeMembersRights/", payload);
        return response.data;
    }
    static async deleteParticipants(teamId: string, userId: string): Promise<void> {
        await httpClient.delete(`/team/${teamId}/member/${userId}`);
    }
    static async addUserTeam(teamId: string, payload: ISendInvitedColleague): Promise<void> {
        await httpClient.post("/team/" + teamId + "/member", payload);
    }
    static async getOnlyDiagrams(): Promise<IDiagramOption[]> {
        const response: AxiosResponse = await httpClient.get("/diagram/only");
        return response.data;
    }
    static async getMyTemplates(): Promise<IMyTemplate[]> {
        const response: AxiosResponse = await httpClient.get("/document-template");
        return response.data;
    }
    static async deleteTemplate(id: string): Promise<void> {
        await httpClient.delete("/document-template?templateId=" + id);
    }
    static async saveTemplate(payload: any): Promise<IMyTemplate> {
        const response: AxiosResponse = await httpClient.post("/document-template?templateId=", payload);
        return response.data;
    }
    static async updateSettingsTeam(payload: IPayloadUpdateAllowedDomain): Promise<ITeam> {
        const response: AxiosResponse = await httpClient.post("/team/update", payload);
        return response.data;
    }
    static async getSettings(): Promise<IResponseSettingsQuality[]> {
        const response: AxiosResponse = await httpClient.get("/team/settings");
        return response.data;
    }
    static async saveNewSettingsQuality(payload: IPayloadSaveNewSettingsQuality[]): Promise<IResponseSettingsQuality[]> {
        const response: AxiosResponse = await httpClient.post("/team/settings", payload);
        return response.data;
    }
}
